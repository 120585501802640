import { AdditionalDocumentUploadedData, AccountCreationSubmissionPayload, AdditionalDocumentUploadedPayload, CancelInviteData, CancelInvitePayload, CodatFinancialsConnectedData, CodatFinancialsConnectedPayload, CompanyInfoSubmissionPayload, CreateSessionCookiePayload, EMPTY_SESSION_COOKIE_VALUE, FundingRequestDocumentUploadedData, FundingRequestDocumentUploadedPayload, GetBorrowerDetailsData, GetMasterFinanceAgreementSigningURLData, GetUploadURLData, GetUploadURLPayload, HelpRequestPayload, InviteUserData, InviteUserPayload, Maybe, MessagingAPIForBrowser, MessagingAPIForBrowserSettings, OmitSessionCookie, ResendInviteData, ResendInvitePayload, RespondToInvitationData, RespondToInvitationPayload, RetailDemandDocumentUploadedData, RetailDemandDocumentUploadedPayload, isBrowser, RemoveUserPayload, RemoveUserData, GetBankHolidaysByYearPayload, GetBankHolidaysByYearData, DeleteFundingRequestData, DeleteFundingRequestPayload, GetFundingRequestDocumentsPayload, GetFundingRequestDocumentsData } from 'lunr-core/browser'
import { BrowserConfiguration, getBrowserConfiguration } from './BrowserConfiguration'

let instance: Maybe<BorrowersMessagingAPIForBrowser> = null

/**
 * A client-side helper for obtaining a messaging reference
 * to send messages to the queue.
 */
export const getMessagingAPIForBrowser = (): BorrowersMessagingAPIForBrowser => {
  if (!isBrowser()) {
    throw new Error('Do not call this from the server. Use the getMessagingAPIForServer() helper instead.')
  }

  if (instance) {
    return instance
  }

  const config: BrowserConfiguration = getBrowserConfiguration()
  instance = new BorrowersMessagingAPIForBrowser({
    apiKey: config.API_KEY,
    getProgressEndpoint: '/api/messaging/get-progress',
    sendEndpoint: '/api/messaging/send'
  })

  return instance
}

/**
 * A wrapper around the MessagingAPI that includes the borrowers-specific helpers.
 */
export class BorrowersMessagingAPIForBrowser extends MessagingAPIForBrowser {
  constructor(settings: MessagingAPIForBrowserSettings) {
    super(settings)
  }

  sendAccountCreationSubmission = async (payload: AccountCreationSubmissionPayload): Promise<boolean> => {
    return this.sendPassFailMessage({
      type: 'AccountCreationSubmissionMessage',
      payload: payload
    })
  }

  sendCompanyInfoSubmission = async (payload: OmitSessionCookie<CompanyInfoSubmissionPayload>): Promise<boolean> => {
    return this.sendPassFailMessage({
      type: 'CompanyInfoSubmissionMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  codatFinancialsConnected = async (payload: OmitSessionCookie<CodatFinancialsConnectedPayload>): Promise<Maybe<CodatFinancialsConnectedData>> => {
    return this.sendTypedResponse<CodatFinancialsConnectedData>({
      type: 'CodatFinancialsConnectedMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  createSessionCookie = async (payload: OmitSessionCookie<CreateSessionCookiePayload>): Promise<boolean> => {
    return this.sendPassFailMessage({
      type: 'CreateSessionCookieMessage',
      payload: payload
    })
  }

  getBorrowerDetails = async (): Promise<Maybe<GetBorrowerDetailsData>> => {
    return this.sendTypedResponse<GetBorrowerDetailsData>({
      type: 'GetBorrowerDetailsMessage',
      payload: {
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  deleteFundingRequest = async (payload: OmitSessionCookie<DeleteFundingRequestPayload>): Promise<Maybe<DeleteFundingRequestData>> => {
    return this.sendTypedResponse<DeleteFundingRequestData>({
      type: 'DeleteFundingRequestMessage',
      payload: {
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE,
        fundingRequestDocumentId: payload.fundingRequestDocumentId
      }
    })
  }

  getFundingRequests = async (payload: OmitSessionCookie<GetFundingRequestDocumentsPayload>): Promise<Maybe<GetFundingRequestDocumentsData>> => {
    return this.sendTypedResponse<GetFundingRequestDocumentsData>({
      type: 'GetFundingRequestDocumentsMessage',
      payload: {
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE,
        status: payload.status
      }
    })
  }

  getMasterFinanceAgreementSigningURL = async (): Promise<Maybe<GetMasterFinanceAgreementSigningURLData>> => {
    return this.sendTypedResponse<GetMasterFinanceAgreementSigningURLData>({
      type: 'GetMasterFinanceAgreementSigningURLMessage',
      payload: {
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  getUploadURL = async (payload: OmitSessionCookie<GetUploadURLPayload>): Promise<Maybe<GetUploadURLData>> => {
    return this.sendTypedResponse<GetUploadURLData>({
      type: 'GetUploadURLMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  additionalDocumentUploaded = async (payload: OmitSessionCookie<AdditionalDocumentUploadedPayload>): Promise<Maybe<AdditionalDocumentUploadedData>> => {
    return this.sendTypedResponse<AdditionalDocumentUploadedData>({
      type: 'AdditionalDocumentUploadedMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  retailDemandDocumentUploaded = async (payload: OmitSessionCookie<RetailDemandDocumentUploadedPayload>): Promise<Maybe<RetailDemandDocumentUploadedData>> => {
    return this.sendTypedResponse<RetailDemandDocumentUploadedData>({
      type: 'RetailDemandDocumentUploadedMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  fundingRequestDocumentUploaded = async (payload: OmitSessionCookie<FundingRequestDocumentUploadedPayload>): Promise<Maybe<FundingRequestDocumentUploadedData>> => {
    return this.sendTypedResponse<FundingRequestDocumentUploadedData>({
      type: 'FundingRequestDocumentUploadedMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  helpRequest = async (payload: OmitSessionCookie<HelpRequestPayload>): Promise<boolean> => {
    return this.sendPassFailMessage({
      type: 'HelpRequestMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  inviteUser = async (payload: OmitSessionCookie<InviteUserPayload>): Promise<Maybe<InviteUserData>> => {
    return this.sendTypedResponse<InviteUserData>({
      type: 'InviteUserMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  cancelInvitation = async (payload: OmitSessionCookie<CancelInvitePayload>): Promise<Maybe<CancelInviteData>> => {
    return this.sendTypedResponse<CancelInviteData>({
      type: 'CancelInviteMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  resendInvitation = async (payload: OmitSessionCookie<ResendInvitePayload>): Promise<Maybe<ResendInviteData>> => {
    return this.sendTypedResponse<ResendInviteData>({
      type: 'ResendInviteMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  respondToInvitation = async (payload: OmitSessionCookie<RespondToInvitationPayload>): Promise<Maybe<RespondToInvitationData>> => {
    return this.sendTypedResponse<RespondToInvitationData>({
      type: 'RespondToInvitationMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  removeUser = async (payload: OmitSessionCookie<RemoveUserPayload>): Promise<Maybe<RemoveUserData>> => {
    return this.sendTypedResponse<RemoveUserData>({
      type: 'RemoveUserMessage',
      payload: {
        ...payload,
        sessionCookie: EMPTY_SESSION_COOKIE_VALUE
      }
    })
  }

  getBankHolidaysByYear = (payload: OmitSessionCookie<GetBankHolidaysByYearPayload>): Promise<Maybe<GetBankHolidaysByYearData>> => {
    return this.sendTypedResponse<GetBankHolidaysByYearData>({
      type: 'GetBankHolidaysByYear',
      payload: {
        ...payload
      }
    })
  }
}
