import cx from 'classnames'

interface Props {
  className?: string
}

export default (props:Props) => {
  return (
    <svg 
      className={cx('Logo', props.className)} 
      xmlns='http://www.w3.org/2000/svg' 
      viewBox='0 0 1259.34 214.6'
      aria-label='LUNR App Home'
    >
      <g>
        <path fill='currentColor' d='m280.38,48.22c7.53,8.72,7.18,13.5,6.97,16.36-.03.41-.06.77-.06,1.09,0,20.01-7.82,38.29-23.89,55.89-16.02,17.54-40.91,35.11-76.09,53.73-.07.04-.14.08-.22.11.18-.1.24-.32.15-.51-.1-.19-.33-.26-.52-.17.09-.04.16-.07.22-.11,35.1-18.58,59.92-36.11,75.88-53.58,15.94-17.45,23.69-35.57,23.69-55.38,0-.35.03-.74.06-1.15.2-2.74.53-7.33-6.79-15.8l-26.75-29.63c2.13,3.9,4.52,9.96,5.69,18.65-.03-.16-.17-.29-.34-.31-.16-.02-.32-.03-.48-.05-1.7-11.99-5.71-18.75-7.97-21.74l-2.26-2.51h0s0-.01,0-.01c-8.5-8.51-17.77-12.3-30.05-12.3-27.03,0-73.08,10.72-111.98,26.07C77.49,38.04,28.22,61.43,14.74,93.2c-.41.95-2.45,6.3-2.73,7.28-4.6,15.93-7.75,27.43-8.44,30.82-.25.26-.49.53-.72.8,0,0,0,0,0,.01-.01.01-.02.03-.03.04,0,0-.01.01-.01.02-.01.02-.02.05-.03.07,0,0,0,.01,0,.02,0,.01,0,.02,0,.03-.02.07-.03.14-.05.21-.15-.55.29-2.77,3.35-13.83,1.98-7.18,4.31-15.27,5.22-18.41.3-1.03,2.34-6.38,2.76-7.36,5.39-12.69,16.96-25.26,34.38-37.35,15.24-10.57,34.4-20.44,56.95-29.34,19.86-7.84,41.36-14.49,62.18-19.22C187.11,2.54,204.9.08,217.63.08s21.94,3.86,30.59,12.52h0c.2.18,1.1.96,2.31,2.57l29.83,33.04Z'/>
        <path fill='currentColor' d='m279.8,48.73c7.32,8.47,6.98,13.06,6.79,15.8-.03.41-.06.79-.06,1.15,0,19.81-7.75,37.92-23.69,55.38-15.96,17.47-40.78,34.99-75.88,53.58-.06.04-.13.07-.22.11,0,0,0,0,0,0-2.13,1.08-4.58,2.34-7.17,3.68-2.42,1.25-5.17,2.67-8.05,4.13-.04.02-.09.04-.14.07-7.08,3.51-14.61,7.12-22.43,10.74,5.61-3.67,11.14-7.49,16.47-11.39.01,0,.02-.01.03-.02,0,0,.01-.01.02-.01,19.06-13.93,36-29.06,50.37-44.95,14.09-15.59,25.04-31.19,32.57-46.37.49-.97,4.03-8.8,5.12-11.68,3.97-10.46,5.98-20.32,5.98-29.32,0-4.15-.25-8.13-.75-11.84,0,0,0-.01,0-.02-1.17-8.7-3.56-14.75-5.69-18.65l26.75,29.63Z'/>
        <path fill='currentColor' d='m258.75,37.78c.5,3.71.75,7.7.75,11.84,0,8.99-2.01,18.86-5.98,29.32-1.09,2.88-4.63,10.7-5.12,11.68-7.53,15.18-18.48,30.79-32.57,46.37-14.37,15.9-31.31,31.02-50.37,44.95,0,0-.01.01-.02.01h0c.13-.09.2-.26.16-.42-.05-.2-.26-.33-.47-.28-.02,0-.04.01-.06.01,18.99-13.89,35.87-28.96,50.19-44.8,14.04-15.54,24.96-31.08,32.45-46.2.48-.97,4.01-8.75,5.09-11.61,3.93-10.37,5.93-20.14,5.93-29.04,0-4-.23-7.85-.7-11.44.1.01.2.02.3.03.21.02.4-.13.42-.34,0-.03,0-.06,0-.09Z'/>
        <path fill='currentColor' d='m258.75,37.78s0,.06,0,.09c-.02.21-.21.36-.42.34-.1-.01-.2-.02-.3-.03-.01-.1-.02-.2-.04-.3-.02-.16-.04-.32-.07-.48.16.01.32.03.48.05.17.02.31.15.34.31,0,0,0,.01,0,.02Z'/>
        <path fill='currentColor' d='m257.99,37.88c.01.1.03.2.04.3-2.2-.22-4.56-.32-7.22-.32-25.47,0-57.63,13.14-98.34,29.78-14.8,6.04-30.09,12.3-46.64,18.57-10,3.79-19.99,7.28-29.66,10.65-31.86,11.13-59.4,20.75-72.57,35.55-.03-.06-.07-.13-.1-.19l-.02-.04s0,0,0-.01c0,0,0,0,0-.01-.01-.02-.02-.03-.03-.05,0,0,0,0,0,0,.02-.17.06-.44.14-.81,13.51-14.61,40.81-24.15,72.34-35.16,9.66-3.37,19.65-6.86,29.64-10.65,16.54-6.27,31.83-12.52,46.62-18.56,40.79-16.67,73.01-29.84,98.63-29.84,2.61,0,4.95.1,7.12.31.02.16.04.32.07.48Z'/>
        <path fill='currentColor' d='m257.92,37.4c-2.17-.21-4.51-.31-7.12-.31-25.62,0-57.84,13.17-98.63,29.84-14.79,6.04-30.08,12.29-46.62,18.56-9.99,3.79-19.98,7.27-29.64,10.65-31.53,11.01-58.83,20.55-72.34,35.16.69-3.39,3.84-14.89,8.44-30.82.28-.98,2.32-6.32,2.73-7.28,13.49-31.77,62.75-55.16,90.91-66.27C144.55,11.57,190.6.85,217.63.85c12.28,0,21.55,3.79,30.05,12.3h0s0,0,0,.01l2.26,2.51c2.26,2.99,6.27,9.74,7.97,21.74Z'/>
        <path fill='currentColor' d='m187.25,174.9c.09.18.02.41-.15.51-.01,0-.02.01-.03.02-.02.01-.05.02-.07.04-2.11,1.06-4.52,2.31-7.08,3.63-2.38,1.22-5.07,2.61-7.88,4.05-.06.03-.12.06-.18.09-8.6,4.27-17.87,8.68-27.54,13.1-.05.02-.1.03-.16.03-.14,0-.27-.07-.34-.2-.07-.13-.06-.28.02-.39,10.27-4.51,19.72-9.19,27.53-13.16.04-.02.09-.04.14-.07,5.31-2.65,10.43-5.28,15.22-7.81,0,0,0,0,0,0,.19-.1.42-.02.52.17Z'/>
        <path fill='currentColor' d='m179.92,179.09c2.56-1.32,4.97-2.57,7.08-3.63-4.69,2.49-9.73,5.07-14.96,7.68,2.81-1.43,5.5-2.82,7.88-4.05Z'/>
        <path fill='currentColor' d='m179.56,178.41c2.59-1.34,5.04-2.6,7.17-3.68-4.78,2.53-9.9,5.16-15.22,7.81,2.88-1.46,5.62-2.88,8.05-4.13Z'/>
        <path fill='currentColor' d='m165.6,181.54c.04.16-.02.33-.16.42-.03.02-.07.04-.12.05-.65.17-1.29.33-1.93.48.54-.39,1.08-.77,1.61-1.17.01-.01.03-.02.04-.03.01,0,.02-.01.03-.02.02,0,.04-.01.06-.01.21-.05.42.07.47.28Z'/>
        <path fill='currentColor' d='m165.44,181.95h0s-.02.01-.03.02c-5.33,3.9-10.86,7.72-16.47,11.39,7.83-3.62,15.36-7.23,22.43-10.74-7.81,3.97-17.26,8.65-27.53,13.16.02-.04.06-.08.11-.11,6.62-4.2,13.16-8.62,19.44-13.17.64-.15,1.28-.32,1.93-.48.04-.01.08-.03.12-.05Z'/>
        <path fill='currentColor' d='m165.08,181.27s-.02.01-.03.02c-.02,0-.03.02-.04.03-.53.39-1.07.78-1.61,1.17-12.19,3.03-22.56,4.27-35.3,4.27-.83,0-1.91.02-3.15.03-1.84.03-4.13.07-6.79.07-12.01,0-31.57-.73-51.64-6.16-30.19-8.16-51.46-24.04-63.23-47.22.07-.32.14-.64.21-.96.03-.04.07-.08.11-.12,11.59,23.37,32.82,39.37,63.11,47.56,24.42,6.6,48.1,6.23,58.22,6.07,1.25-.02,2.32-.04,3.16-.04,13.31,0,24.03-1.37,36.98-4.72Z'/>
        <path fill='currentColor' d='m144.32,196.33c9.67-4.42,18.94-8.83,27.54-13.1-12.18,6.19-28.35,14.12-45.44,20.45-19.4,7.19-36.16,10.83-49.82,10.83-8.56,0-21.49-.86-34.45-4.93-13.3-4.19-23.64-10.81-30.74-19.68C.25,175.94-2.68,156.64,2.71,132.51c.04.13.11.17.18.21,0,0,0,0,0,0,.13.26.25.51.39.77-5.09,23.51-2.16,42.32,8.73,55.94,7,8.75,17.22,15.29,30.37,19.42,12.87,4.05,25.71,4.9,34.22,4.9,13.57,0,30.24-3.63,49.55-10.79,6.06-2.25,12-4.69,17.69-7.19-.08.11-.09.26-.02.39.07.13.2.2.34.2.06,0,.11-.01.16-.03Z'/>
        <path fill='currentColor' d='m163.39,182.49c-6.29,4.55-12.82,8.98-19.44,13.17-.05.03-.09.07-.11.11-5.69,2.5-11.63,4.94-17.69,7.19-19.31,7.16-35.98,10.79-49.55,10.79-8.51,0-21.35-.85-34.22-4.9-13.15-4.14-23.37-10.67-30.37-19.42-10.89-13.61-13.82-32.43-8.73-55.94,11.77,23.18,33.04,39.06,63.23,47.22,20.07,5.42,39.63,6.16,51.64,6.16,2.66,0,4.95-.03,6.79-.07,1.25-.02,2.32-.03,3.15-.03,12.74,0,23.11-1.25,35.3-4.27Z'/>
        <path fill='currentColor' d='m3.6,132.41s-.07.08-.11.12c0-.03.01-.07.02-.1.01-.04.01-.1,0-.15,0-.02,0-.04-.01-.06.03.06.06.13.1.19Z'/>
        <path fill='currentColor' d='m3.51,132.28c0,.05,0,.1,0,.15-.01.06-.04.12-.09.17-.08.09-.18.13-.29.13-.09,0-.18-.03-.25-.1-.04-.04-.08-.08-.1-.13,0,0,0,0,0-.01,0-.02-.01-.04-.02-.07-.01-.04-.01-.09,0-.13,0-.01,0-.02,0-.03,0,0,0-.01,0-.02,0-.02.01-.05.03-.07v-.02s.03-.03.04-.04c.01-.01.02-.02.04-.04.01-.01.02-.02.04-.03l.05-.03h0s.04-.02.07-.03c.01,0,.03,0,.04,0,.04,0,.08,0,.11,0h.05s.03.02.05.03c.01,0,.03.01.04.02.01,0,.03.02.04.02.02.02.04.04.06.06h0s.02.04.03.06c0,0,0,0,0,.01,0,0,0,0,0,.01.02.03.03.07.03.1Z'/>
        <path fill='currentColor' d='m3.5,132.22s.01.04.01.06c0-.03-.02-.07-.03-.1l.02.04Z'/>
        <path fill='currentColor' d='m3.51,132.43s-.01.07-.02.1c-.02.02-.04.05-.07.07.04-.05.07-.11.09-.17Z'/>
        <path fill='currentColor' d='m3.49,132.53c-.07.32-.14.64-.21.96-.13-.25-.26-.51-.39-.77.13.07.35.09.53-.12.02-.02.04-.05.07-.07Z'/>
        <path fill='currentColor' d='m2.85,132.09c.23-.27.47-.53.72-.8-.08.36-.12.64-.14.81-.02-.02-.04-.04-.06-.06-.01-.01-.02-.02-.04-.02-.01-.01-.03-.02-.04-.02-.02-.01-.03-.01-.05-.02,0,0-.01,0-.02,0-.01,0-.02,0-.03,0-.04,0-.08,0-.11,0-.01,0-.02,0-.04,0-.02,0-.04.01-.07.03h0l-.05.03s-.03.02-.04.03c-.01.01-.03.02-.04.04,0,0,0,0,0-.01Z'/>
        <path fill='currentColor' d='m3.47,132.16s0,0,0,.01c0,0,0,0,0-.01Z'/>
        <path fill='currentColor' d='m3.43,132.1s0,0,0,0h0Z'/>
        <path fill='currentColor' d='m3.37,132.04s-.02-.02-.04-.02c.01,0,.03.01.04.02Z'/>
        <path fill='currentColor' d='m3.29,132s-.03-.01-.05-.02c.02,0,.03,0,.05.02Z'/>
        <path fill='currentColor' d='m3.22,131.97s.01,0,.02,0h-.05s.02-.01.03,0Z'/>
        <path fill='currentColor' d='m3.14,132.73c.11,0,.21-.04.29-.13-.18.21-.4.19-.53.12-.03-.07-.07-.13-.1-.2,0,0,0-.01,0-.01.02.05.06.09.1.13.07.07.16.1.25.1Z'/>
        <path fill='currentColor' d='m3.19,131.97s-.08,0-.11,0c.04,0,.08,0,.11,0Z'/>
        <path fill='currentColor' d='m3.04,131.98s-.04.01-.07.03c.02-.01.04-.02.07-.03Z'/>
        <path fill='currentColor' d='m2.92,132.03s-.03.02-.04.03c.01-.01.02-.02.04-.03Z'/>
        <path fill='currentColor' d='m2.89,132.72s0,0,0,0c-.07-.04-.14-.08-.18-.21.02-.07.03-.14.05-.21,0,.04,0,.09,0,.13,0,.02.01.04.02.07,0,0,0,.01,0,.01,0,0,0,.01,0,.01.03.07.07.14.1.2Z'/>
        <path fill='currentColor' d='m2.81,132.15v.02s0-.01,0-.02Z'/>
        <path fill='currentColor' d='m2.76,132.42s.01.05.02.07c-.01-.02-.02-.04-.02-.07Z'/>
        <path fill='currentColor' d='m2.76,132.26s0-.01,0-.02c0,0,0,.01,0,.02Z'/>
      </g>
      <g>
        <path fill='currentColor' d='m555.05,150.14V26.48c0-3.26,2.64-5.9,5.9-5.9h22.46c3.4,0,6.15,2.75,6.15,6.15v120.91c0,13.01,5.75,17.76,18.01,17.76h120.56c12.26,0,18.01-4.75,18.01-17.76V26.48c0-3.26,2.64-5.9,5.9-5.9h22.46c3.26,0,5.9,2.64,5.9,5.9v123.66c0,28.76-13.76,45.52-44.77,45.52h-135.81c-31.01,0-44.77-16.76-44.77-45.52Z'/>
        <path fill='currentColor' d='m537.76,190.26c-.03,2.97-2.45,5.37-5.42,5.37h-122.67c-31.01,0-44.77-16.76-44.77-45.52V26.84c0-3.47,2.82-6.29,6.29-6.29h21.69c3.61,0,6.54,2.93,6.54,6.54v120.52c0,13.01,5.75,17.76,18.01,17.76h114.7c3.23,0,5.83,2.63,5.8,5.86l-.17,19.04Z'/>
        <path fill='currentColor' d='m802.92,189.76V26.48c0-3.26,2.64-5.9,5.9-5.9h30.37c2.22,0,3.23.98,5.42,2.69.05.04.1.08.15.13l139.62,131.41c.4.38.93.58,1.47.58h0c1.19,0,2.15-.96,2.15-2.15V26.48c0-3.26,2.64-5.9,5.9-5.9h22.46c3.4,0,6.15,2.75,6.15,6.15v162.78c0,3.4-2.75,6.15-6.15,6.15h-30.12c-2.5,0-3.5-1-5.5-2.75l-139.69-131.48c-.4-.38-.93-.58-1.47-.58h0c-1.19,0-2.15.96-2.15,2.15v126.51c0,3.4-2.75,6.15-6.15,6.15h-22.46c-3.26,0-5.9-2.64-5.9-5.9Z'/>
        <path fill='currentColor' d='m1258.38,189.66l-26.98-40.94c-.76-1.16-.24-2.72,1.06-3.2,16.45-6.11,23.92-20.72,23.92-41.9v-37.52c0-28.76-13.76-45.52-44.77-45.52h-160.68c-3.26,0-5.9,2.64-5.9,5.9v163.28c0,3.26,2.64,5.9,5.9,5.9h22.71c3.26,0,5.9-2.64,5.9-5.9v-38.72l.03-2.18,113.39.03c.71,0,1.37.35,1.77.94l28.62,41.83c1.75,2.5,3.5,4,6.25,4h24.51c4,0,6.75-2.75,4.25-6Zm-36.27-88.79c0,13.01-5.75,17.76-18.26,17.76h-124.31V53c0-1.19.96-2.15,2.15-2.15h122.16c12.51,0,18.26,4.75,18.26,17.76v32.26Z'/>
      </g>
    </svg>
  )
}