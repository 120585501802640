import { BrowserConfiguration, getBrowserConfiguration } from './BrowserConfiguration'

const config:BrowserConfiguration = getBrowserConfiguration()

export default {
  INVITE_ERROR: '/invite/error',

  LOGIN: '/',
  
  ONBOARDING: '/onboarding', // Note, this is the same as the ONBOARDING_SIGNUP route.
  ONBOARDING_SIGNUP: '/onboarding/signup',
  ONBOARDING_COMPANY_INFO: '/onboarding/company-info',
  ONBOARDING_CONNECT_FINANCIALS: '/onboarding/connect-financials',
  ONBOARDING_APPLICATION_STATUS: '/onboarding/application-status',
  ONBOARDING_ALMOST_DONE: '/onboarding/almost-done',
  
  SECURE_DASHBOARD: '/secure',
  SECURE_FUNDING_REQUESTS: '/secure/funding-requests',
  SECURE_FUNDING_REQUESTS_NEW: '/secure/funding-requests/new',
  SECURE_RETAIL_DEMAND_DOCUMENTS_NEW: '/secure/retail-demand-documents',
  SECURE_HELP: '/secure/help',
  SECURE_ACCOUNT_SETTINGS: '/secure/account-settings',
  SECURE_ADDITIONAL_DOCUMENTS_NEW: '/secure/additional-documents/new',
  SECURE_DOCUSIGN_RETURN: '/onboarding/docusign/return',

  DOWNLOAD_DOCUMENT: (documentIDs:Array<number> | number, documentInvoice?:string):string => {
    if (!Array.isArray(documentIDs)) {
      documentIDs = [documentIDs]
    }
    const documentIdsCommaSeparated = documentIDs.join(',')

    const urlParams = new URLSearchParams()

    urlParams.append('apiKey', config.API_KEY)
    urlParams.append('documentIDs', documentIdsCommaSeparated)

    if (documentInvoice) {
      urlParams.append('documentInvoice', documentInvoice)
    }

    return `/api/download?${urlParams.toString()}`
  },

  DOWNLOAD_MFA: '/api/download-signed-mfa'
}